import React from 'react';
import { Helmet } from "react-helmet";
import { ENV_NAME } from '../config.js';


export const FbPixel = ({ productType }) => {
    let FBPixelId = '655388214833626';
    if(productType === 'Car'){
        FBPixelId = '2374133592810678';
    }
    if(productType === 'Home'){
        FBPixelId = '2374133592810678';
    }
    return(
        <Helmet>
            { (ENV_NAME === "production" || true) && (
                <script>{`
                    <!-- Facebook Pixel Code -->
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', ${FBPixelId});
                    fbq('track', 'ViewContent');
                    <!-- End Facebook Pixel Code -->
                    `}
                </script>
            )}
        </Helmet>
    );
}

export default FbPixel
